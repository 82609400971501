import React, { useContext, useState } from 'react'

import { useVehicleStore } from 'Clutch/Stores/VehicleStore'
import classNames from 'classnames'

import {
  addToGarage,
  showAddToGarage,
  showUpdateCustomerProject,
  updateCustomerProject,
} from 'Clutch/Utilities/CustomerProjectUtilities/customerProjectUtilities'
import getFitmentDetailsFromSelectedFacets from 'Clutch/Utilities/CustomerProjectUtilities/getFitmentDetailsFromSelectedFacets'
import getMatchingProject from 'Clutch/Utilities/CustomerProjectUtilities/getMatchingProject'
import { createClickedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents'

import { NavigationContext } from 'Clutch/Contexts/Navigation/NavigationContext'
import { NotificationContext } from 'Clutch/Contexts/NotificationContext/NotificationContext'
import PanelContext from 'Clutch/Contexts/PanelContext'

import Button from 'Clutch/Molecules/Button'

import Card from 'Clutch/Atoms/Card'
import Panel from 'Clutch/Atoms/Panel'
import Svg from 'Clutch/Atoms/Svg'
import TextLink from 'Clutch/Atoms/TextLink'
import Typography from 'Clutch/Atoms/Typography'

import useProductResultPage from '../../hooks/useProductResultPage'
import useVehicleSidebar from '../../hooks/useVehicleSidebar'
import VehicleSelect from '../sidebar/vehicleSelect/vehicleSelect'
import styles from './vehicleSelectedDisplay.module.scss'

const panelContextValue = { layer: 0 }

const VehicleSelectedDisplay = () => {
  const [loading, setLoading] = useState(false)

  function handleEditIconClick() {
    navigationDispatch({
      type: 'setCustomerProjectDrawerState',
      payload: {
        garageState: {
          isCustomerProjectDrawerOpen: true,
        },
      },
    })
  }

  const selectedFacetOptions = useProductResultPage(
    (x) => x.selectedFacetOptions,
  )
  const totalSearchResults = useProductResultPage((x) => x.totalSearchResults)
  const engineFitmentPageTitle = useProductResultPage(
    (x) => x.engineFitmentPageTitle,
  )

  const selectedFacets = selectedFacetOptions || []
  const customerProjects = useVehicleStore(
    (state) => state.context.customerProjects,
  )
  const fitmentDetails =
    getFitmentDetailsFromSelectedFacets(selectedFacets) || {}
  const { vehicleName } = useVehicleSidebar(
    selectedFacets,
    fitmentDetails,
    engineFitmentPageTitle,
  )
  const updateCustomerProjectData = useVehicleStore(
    (x) => x.updateCustomerProjectData,
  )

  const { PushNotification } = useContext(NotificationContext)
  const selectCustomerProject = useVehicleStore(
    (state) => state.selectCustomerProject,
  )
  const { dispatch: navigationDispatch } = useContext(NavigationContext)
  const addCustomerProject = useVehicleStore((x) => x.addCustomerProject)

  const canAdd = showAddToGarage(customerProjects, fitmentDetails)
  const canUpdate = showUpdateCustomerProject(customerProjects, fitmentDetails)

  const hasYMM =
    fitmentDetails.year && fitmentDetails.make && fitmentDetails.model
  const hasRaceType = fitmentDetails.raceType || fitmentDetails.raceTypeName
  const hasSubtext =
    (hasYMM || hasRaceType) &&
    (engineFitmentPageTitle ||
      fitmentDetails.engineFitmentDisplayValue ||
      (!canUpdate && !canAdd))

  async function handleAddEngineClick() {
    const selectedProject = getMatchingProject(fitmentDetails, customerProjects)
    selectCustomerProject(selectedProject.projectId, null)
      .then(() => {
        navigationDispatch({
          type: 'setCustomerProjectDrawerState',
          payload: {
            garageState: {
              isCustomerProjectDrawerOpen: true,
              customerProjectDrawerView: 'UPDATE_PROJECT_ADD_ENGINE',
            },
          },
        })
      })
      .catch((error) => {
        console.error('Error selecting customer project:', error)
      })
  }

  function handleUpdateVehicle() {
    updateCustomerProject(
      fitmentDetails,
      canUpdate,
      setLoading,
      selectCustomerProject,
      updateCustomerProjectData,
      PushNotification,
      vehicleName
    )
  }

  function handleAddToGarage() {
    addToGarage(
      fitmentDetails,
      setLoading,
      selectCustomerProject,
      addCustomerProject,
      PushNotification,
      vehicleName
    )
  }

  return (
    <>
      {totalSearchResults && (
        <Typography font={'bold'} className={styles.resultsTitle}>
          {totalSearchResults} products fit your{' '}
        </Typography>
      )}
      <Card fill noPadding layer={1} className={styles.card}>
        <div className={styles.cardContents}>
          <div className={styles.title}>
            <>
              <div className={styles.spaceBetween}>
                <div className={styles.itemText}>
                  <Panel layer={0} className={styles.svg}>
                    {totalSearchResults.toString() === '0' ? (
                      <Svg icon={'unavailable'} size={2} intent={'negative'} />
                    ) : (
                      <Svg icon={'check'} size={2} intent={'positive'} />
                    )}
                  </Panel>
                  <div className={styles.completeVehicleText}>
                    {hasYMM ? (
                      <Typography
                        data-testid={'PrpFitmentDetailsTitle'}
                        font={'bold'}
                      >
                        {fitmentDetails.year} {fitmentDetails.make}{' '}
                        {fitmentDetails.model}
                      </Typography>
                    ) : (
                      hasRaceType && (
                        <Typography
                          data-testid={'PrpFitmentDetailsTitle'}
                          font={'bold'}
                        >
                          {fitmentDetails.raceType ||
                            fitmentDetails.raceTypeName}
                        </Typography>
                      )
                    )}
                    {engineFitmentPageTitle ||
                    fitmentDetails.engineFitmentDisplayValue ? (
                      <Typography
                        data-testid={
                          hasYMM || hasRaceType
                            ? 'PrpFitmentDetailsSubtitle'
                            : 'PrpFitmentDetailsTitle'
                        }
                        className={styles.vehicleSubtext}
                        font={hasYMM || hasRaceType ? undefined : 'bold'}
                      >
                        {engineFitmentPageTitle ??
                          fitmentDetails.engineFitmentDisplayValue}
                      </Typography>
                    ) : (
                      !canUpdate &&
                      !canAdd && (
                        <TextLink
                          onClick={handleAddEngineClick}
                          className={styles.vehicleSubtext}
                          data-testid={'PrpAddEngine'}
                          tone={'normal'}
                          size={1}
                          chevron={'right'}
                        >
                          {'Add Engine'}
                        </TextLink>
                      )
                    )}
                  </div>
                  <div
                    className={
                      hasSubtext
                        ? classNames(
                            styles.editIcon,
                            styles.hideDesktop,
                            styles.withSubtext,
                          )
                        : classNames(
                            styles.editIcon,
                            styles.hideDesktop,
                            styles.onlyTitle,
                          )
                    }
                    onClick={handleEditIconClick}
                  >
                    <Svg icon={'edit'} size={1} />
                  </div>
                </div>
                <div className={styles.controls}>
                  <PanelContext.Provider value={panelContextValue}>
                    <VehicleSelect
                      styling={styles.switch}
                      textSize={0.75}
                      bold
                      displayNameOverride={`Show Direct Fit Parts`}
                    />
                  </PanelContext.Provider>
                  <div className={styles.buttonWrapper}>
                    <Button
                      intent={'subdued'}
                      size={'small'}
                      className={styles.changeSelected}
                      onClick={handleEditIconClick}
                      segmentEvent={createClickedSegmentEvent(
                        'PrpChangeVehicle',
                      )}
                      testingName={'ChangeVehicle'}
                    >
                      <Typography className={styles.nowrap} font={'bold'}>
                        Change Selected
                      </Typography>
                    </Button>
                    {canUpdate && (
                      <Button
                        isWaiting={loading}
                        brand={'secondary'}
                        size={'small'}
                        className={styles.vehicleButton}
                        onClick={handleUpdateVehicle}
                        testingName={'PrpUpdateGarage'}
                        segmentEvent={createClickedSegmentEvent(
                          'UpdateProject',
                        )}
                      >
                        <Typography className={styles.nowrap} font={'bold'}>
                          Update Vehicle
                        </Typography>
                      </Button>
                    )}
                    {!canUpdate && canAdd && (
                      <Button
                        isWaiting={loading}
                        brand={'secondary'}
                        size={'small'}
                        className={styles.vehicleButton}
                        onClick={handleAddToGarage}
                        testingName={'PrpAddToGarage'}
                        segmentEvent={createClickedSegmentEvent('AddProject')}
                      >
                        <Typography className={styles.nowrap} font={'bold'}>
                          Add To Garage
                        </Typography>
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </Card>
    </>
  )
}

export default VehicleSelectedDisplay
