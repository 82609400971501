import AppName from 'AppName'
import ClassNames from 'classnames'

import { StorageAccessor } from 'Clutch/Utilities/LocalStorageUtility'

import {
  Default,
  Selected,
} from 'Clutch/Molecules/Button/Variants/OptionButtons'

import Divider from 'Clutch/Atoms/Divider'
import Svg from 'Clutch/Atoms/Svg'
import Typography from 'Clutch/Atoms/Typography'

import { PrpListSelectedKey } from '../../constants/localStorageKeys'
import useProductResultPage from '../../hooks/useProductResultPage'
import styles from './options.module.scss'
import SelectedFacets from './selectedFacets/selectedFacets'
import SortTypeSelect from './sortTypeSelect/sortTypeSelect'

const Options = ({ setProductListView, isProductListView }) => {
  const skeleton = useProductResultPage((x) => x.skeleton)
  const isNoResultPage = useProductResultPage((x) => x.isNoResultPage)
  const totalSearchResults = useProductResultPage((x) => x.totalSearchResults)

  const ListViewButton = isProductListView ? Selected : Default
  const GridViewButton = isProductListView ? Default : Selected

  const handleLayoutToggle = (isList, type) => {
    StorageAccessor.localStorage.setObj(
      PrpListSelectedKey,
      isList ? true : false,
      3600,
    )

    setProductListView(isList)
  }

  function handleListViewClick() {
    return handleLayoutToggle(true, 'List')
  }
  function handleGridViewClick() {
    return handleLayoutToggle(false, 'Grid')
  }

  if (skeleton) {
    return false
  }

  if (isNoResultPage) {
    return (
      <>
        <SelectedFacets location="noResultPage" />
        <Divider className={ClassNames(styles.divider, styles.hideMobile)} />
      </>
    )
  }

  return (
    <>
      <div className={styles.options}>
        <div className={styles.productCardLayoutToggles}>
          <ListViewButton
            data-testid={'listViewButton'}
            title={
              !isProductListView ? `Switch to list view` : `List view (current)`
            }
            key={`list_view_button_${isProductListView}`}
            classNames={styles.layoutToggleButton}
            svg={<Svg icon={'list-view'} size={1.25} />}
            brand={'primary'}
            size={'small'}
            onClick={handleListViewClick}
          />
          <GridViewButton
            data-testid={'gridViewButton'}
            title={
              isProductListView ? `Switch to grid view` : `Grid view (current)`
            }
            key={`grid_view_button_${isProductListView}`}
            classNames={styles.layoutToggleButton}
            svg={<Svg icon={'grid-view'} size={1.25} />}
            brand={'primary'}
            size={'small'}
            onClick={handleGridViewClick}
          />
        </div>
        <Typography size={0.875} className={styles.count}>
          {skeleton ? (
            <>Loading products...</>
          ) : (
            <>
              {totalSearchResults +
                ` ${totalSearchResults == 1 ? 'product' : 'products'}`}
            </>
          )}
        </Typography>

        <div className={styles.sortBy}>
          <SortTypeSelect />
        </div>
      </div>
      <Divider className={ClassNames(styles.divider, styles.hideMobile)} />
      <SelectedFacets wrap />
    </>
  )
}

export default Options
