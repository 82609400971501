import { useContext, useLayoutEffect, useRef, useState, useMemo } from 'react';



import ClassNames from 'classnames';



import { createClickedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents';



import useSegment from 'Clutch/Hooks/useSegment';
import useLogRocket from 'Hooks/useLogRocket';



import Button from 'Clutch/Molecules/Button';



import CoveringSpinner from 'Clutch/Atoms/CoveringSpinner';
import Panel from 'Clutch/Atoms/Panel';
import TextLink from 'Clutch/Atoms/TextLink';
import Typography from 'Clutch/Atoms/Typography';



import { usePrpStore } from '../../contexts/prpContext';
import useProductResultPage from '../../hooks/useProductResultPage';
import styles from './criticalAttributes.module.scss';





const CriticalAttributes = () => {
  const searchResponseFacets = useProductResultPage(
    (x) => x.searchResponseFacets,
  )
  const totalSearchResults = useProductResultPage((x) => x.totalSearchResults)
  const categoryFacet = searchResponseFacets.find(
    (facet) => facet.isForTopCategories,
  )
  const Navigate = usePrpStore((x) => x.Navigate)
  const [isLoading, setIsLoading] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const topCatAreaRef = useRef(null)
  const [expandable, setExpandable] = useState(false)
  const { sendCustomSegmentTrackEvent } = useSegment()
  const { logRocketTrack } = useLogRocket()

  useLayoutEffect(() => {
    const canBeExpanded =
      topCatAreaRef.current?.scrollHeight -
        topCatAreaRef.current?.clientHeight >
      5
    if (categoryFacet) {
      setExpandable(canBeExpanded)
    }
  }, [categoryFacet])

  const options = categoryFacet?.searchResponseFacetOptions || []

  const optionsWithAll = [
    {
      id: options[0]?.id || null, 
      skip: true,
      resultCount: totalSearchResults,
      displayValue: 'All',
    },
    ...options,
  ]

  const numCols = optionsWithAll.length < 4 ? options.length : 4
  const cardContainerStyle = useMemo(() => ({ '--numCols': numCols }), [numCols])

  if (!categoryFacet || !categoryFacet.searchResponseFacetOptions?.length)
    return false

  const handleOptionClick = (e, segmentEvent, option) => {
    sendCustomSegmentTrackEvent(segmentEvent)
    logRocketTrack(`Critical_Attribute_Clicked${option.skip ? '_all' : ''}`)
    Navigate(
      e,
      option.skip
        ? `${window?.location?.pathname}${window?.location?.search}`
        : option.facetOptionLink.url,
      setIsLoading,
      option.skip ? option.id : null,
    )
  }

  function handleExpandClick() {
    setIsExpanded(!isExpanded);
  }

  return (
    <CoveringSpinner
      className={styles.coveringSpinnerWrapper}
      isLoading={isLoading}
      fillWidth
    >
      <Panel
        className={ClassNames(
          styles.container,
          expandable ? styles.extraSpaceForButton : false,
        )}
        layer={0}
      >
        <div className={styles.facet}>
          <Typography size={1.25} font={'bold'} className={styles.title}>
            Select {categoryFacet.facetName}
          </Typography>
          <div
            className={ClassNames(
              styles.cardContainer,
              !isExpanded ? styles.collapsed : false,
            )}
            style={cardContainerStyle}
            ref={topCatAreaRef}
          >
            {optionsWithAll.map((x, i) => {
              const event = createClickedSegmentEvent(
                `CriticalAttribute${i == 0 ? '_Skip' : ''}`,
              )
              function handleClick(e) {
                return handleOptionClick(e, event, x);
              }
              return (
                <TextLink
                  data-testid={`critical_attribute_${x.displayValue}`}
                  key={`critical_attribute_${i}`}
                  onClick={handleClick}
                  as={'div'}
                  className={styles.option}
                >
                  <div className={styles.optionValue}>{x.displayValue}</div>
                  <Typography size={0.75} tone={'superfluous'}>
                    {x.resultCount}
                  </Typography>
                </TextLink>
              )
            })}
          </div>
          {expandable ? (
            <div className={styles.moreWrapper}>
              <Button
                className={styles.more}
                onClick={handleExpandClick}
                segmentEvent={createClickedSegmentEvent(
                  'ExpandCriticalAttributeTopCategories',
                )}
                size={'small'}
                intent={'subdued'}
              >
                See {isExpanded ? 'Less' : 'All'}
              </Button>
            </div>
          ) : (
            false
          )}
        </div>
      </Panel>
    </CoveringSpinner>
  )
}

export default CriticalAttributes