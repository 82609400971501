import React, { useContext, useState } from 'react'

import { updateRecentSearches } from 'Clutch/Layout/Header/RecentSearches/RecentSearches'
import { useUserStore } from 'Clutch/Stores/UserStore/UserStore'

import { createClickedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents'

import { TrackingContext } from 'Contexts/TrackingContext/TrackingContext'

import Button from 'Clutch/Molecules/Button'

import Form from 'Clutch/Atoms/Form'
import Svg from 'Clutch/Atoms/Svg'

import useProductResultPage from '../../../hooks/useProductResultPage'
import SideBarBlockWrapper from '../shared/sideBarBlockWrapper'
import styles from '../sidebar.module.scss'
import AppName from '/appName/appName'

const SearchWithin = ({ dividerBottom, dividerTop }) => {
  const skeleton = useProductResultPage((x) => x.skeleton)
  const searchWithinUrl = useProductResultPage((x) => x.searchWithinUrl)

  const userVin = useUserStore((x) => x.context.userVin)
  const userGuid = useUserStore((x) => x.context.userGuid)
  const { segmentReady } = useContext(TrackingContext)
  const [isLoading, setIsLoading] = useState(false)
  const [isValid, setIsValid] = useState(true)

  if (!searchWithinUrl) {
    return false
  }

  function ExecuteSearch(e) {
    e.preventDefault()
    const searchTerm = e.nativeEvent.target[0].value?.trim() ?? null

    if (!searchTerm) {
      setIsValid(false)
      setTimeout(() => {
        setIsValid(true)
      }, 3000)
      return
    }
    setIsValid(true)
    setIsLoading(true)
    const url = searchWithinUrl.replace(
      'QUERYPLACEHOLDER',
      encodeURIComponent(searchTerm)
    )

    updateRecentSearches(searchTerm)

    if (segmentReady && !skeleton) {
      analytics.track('Products Searched', {
        user_vin: `${userVin()}`,
        web_user_guid: `${userGuid}`,
        query: searchTerm,
        page_type: AppName,
        element: 'search_within',
      })
    }
    window.location.href = url
  }

  return (
    <>
      <SideBarBlockWrapper
        dividerBottom={dividerBottom}
        dividerTop={dividerTop}
      >
        <form className={styles.container} onSubmit={ExecuteSearch}>
          <Form.Textbox
            fill
            placeholder={`Search within results`}
            disabled={isLoading}
            intent={isValid ? null : 'negative'}
            data-testid={`searchWithin`}
          />
          <Button
            testingName={'search_within_submit'}
            size={'small'}
            isWaiting={isLoading}
            className={styles.switchLabel}
            segmentEvent={createClickedSegmentEvent('SearchWithin')}
            brand={'primary'}
            type={'submit'}
            aria-label={`submit search within`}
          >
            <Svg icon={'magnifyingglass'} size={1} />
          </Button>
        </form>
      </SideBarBlockWrapper>
    </>
  )
}

export default SearchWithin
