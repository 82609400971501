import React from 'react'

import getFitmentDetailsFromSelectedFacets from 'Clutch/Utilities/CustomerProjectUtilities/getFitmentDetailsFromSelectedFacets'

import useProductResultPage from '../../hooks/useProductResultPage'
import useVehicleSidebar from '../../hooks/useVehicleSidebar'
import IncompleteYmmSelector from './incompleteYmmSelector'
import SelectVehiclePrompt from './selectVehiclePrompt'
import VehicleSelectedDisplay from './vehicleSelectedDisplay'

const VehicleOptions = () => {

    const selectedFacetOptions = useProductResultPage(
      (x) => x.selectedFacetOptions,
    )
    const incompleteYmmPage = useProductResultPage((x) => x.incompleteYmmPage)
    const engineFitmentPageTitle = useProductResultPage(
      (x) => x.engineFitmentPageTitle,
    )

    const selectedFacets = selectedFacetOptions || []
    const fitmentDetails =
      getFitmentDetailsFromSelectedFacets(selectedFacets) || {}
    const { isVehicleSelected } = useVehicleSidebar(
      selectedFacets,
      fitmentDetails,
      engineFitmentPageTitle,
    )

  try {
    if (!isVehicleSelected) return <SelectVehiclePrompt />

    if (incompleteYmmPage) {
      return <IncompleteYmmSelector />
    }

    return <VehicleSelectedDisplay />
  } catch (err) {
    console.error(
      'There was an issue rendering the vehicle options section',
      err,
    )
  }
}

export default VehicleOptions
