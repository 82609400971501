import { useContext, useEffect, useState, useMemo } from 'react'

import Button from 'Clutch/Molecules/Button'

import CoveringSpinner from 'Clutch/Atoms/CoveringSpinner'
import TextLink from 'Clutch/Atoms/TextLink'
import Typography from 'Clutch/Atoms/Typography'

import { setLastPageVisited } from '../../../Utilities/LocalStorage/previousPage'
import GetFacetToRemove from '../../../Utilities/suggestedFacetsToRemove'
import AppName from '../../../appName/appName'
import { usePrpStore } from '../../../contexts/prpContext'
import styles from './loadMore.module.scss'
import DefaultPageSize from '/constants/pageSize'
import useProductResultPage from '/hooks/useProductResultPage'

const LoadMore = ({ productListView }) => {
  const [waiting, setWaiting] = useState(false)
  const Navigate = usePrpStore((x) => x.Navigate)
  const LoadNextPageProducts = usePrpStore((x) => x.LoadNextPageProducts)
  const [waitingRemoveFacet, setWaitingRemoveFacet] = useState(false)

  const isNoResultPage = useProductResultPage((x) => x.isNoResultPage)
  const totalSearchResults = useProductResultPage((x) => x.totalSearchResults)
  const isSearchPage = useProductResultPage((x) => x.isSearchPage)
  const selectedFacetOptions = useProductResultPage(
    (x) => x.selectedFacetOptions,
  )
  const currentPage = useProductResultPage((x) => x.currentPage)
  const segmentEvent = useMemo(() => ({
    event: 'Clicked',
    properties: {
      element: 'prp - load more',
    },
  }), []);

  function loadMore() {
    try {
      const urlParams = new URLSearchParams(window.location.search)

      if (urlParams.has('page')) {
        urlParams.set('page', currentPage + 1)
      } else {
        urlParams.append('page', currentPage + 1)
      }

      window.history.replaceState(
        null,
        null,
        window.location.pathname + '?' + urlParams.toString().toLowerCase()
      )

      setLastPageVisited()
    } catch (err) {
      console.error(err)
    }

    LoadNextPageProducts(setWaiting)
  }

  useEffect(() => {
    setLastPageVisited()
  }, [currentPage])

  const shownResultsCount = currentPage * DefaultPageSize

  const loadMoreCount =
    shownResultsCount + DefaultPageSize > totalSearchResults
      ? totalSearchResults - shownResultsCount
      : DefaultPageSize

  let removeFiltersLink = null



  if (isNoResultPage || isSearchPage) {
    const facetToRemove = GetFacetToRemove({
      isSearchPage,
      selectedFacetOptions,
    })
    if (facetToRemove) {
      const linkText = `Remove the ${facetToRemove.name} filter?`

      function handleRemoveFacetClick(e) {
        Navigate(e, facetToRemove.url, setWaitingRemoveFacet)
      }

      removeFiltersLink = (
        <CoveringSpinner isLoading={waitingRemoveFacet}>
          <TextLink
            nofollow={facetToRemove.noFollow}
            onClick={handleRemoveFacetClick}
            size={'0.875'}
            intent={'action'}
            underline
            href={facetToRemove.url}
          >
            {linkText}
          </TextLink>
        </CoveringSpinner>
      )
    }
  }

  if (isNoResultPage) {
    return (
      <div className={styles.contain}>
        <Typography
          size={1}
          tone={'subtle'}
          data-testid={'totalSearchResultsCount'}
          className={styles.resultText}
        >
          No Results Found
        </Typography>
      </div>
    )
  }

  const totalResultsInt = (
    <span
      size={0.75}
      tone={'subtle'}
      data-testid={'totalSearchResultsCount'}
      className={styles.whitespace}
    >
      {totalSearchResults}
    </span>
  )

  if (loadMoreCount <= 0) {
    //if no more to load, will display
    //showing all XX results
    //showing 1 result (if only 1 obv)
    return (
      <>
        <div className={styles.contain}>
          <Typography size={1} tone={'subtle'} className={styles.resultText}>
            Showing{totalSearchResults > 1 ? ' all ' : ' '}
            {totalResultsInt} result{totalSearchResults > 1 ? 's' : ''}
          </Typography>
          {removeFiltersLink}
        </div>
      </>
    )
  }

  return (
    <>
      <div className={styles.contain}>
        <Typography size={1} tone={'subtle'} className={styles.resultText}>
          Showing 1 - {shownResultsCount} of {totalResultsInt} results
        </Typography>
        <Button
          isWaiting={waiting}
          intent={'action'}
          size={'small'}
          segmentEvent={segmentEvent}
          text={`Load ${loadMoreCount} More Product${loadMoreCount > 1 ? 's' : ''}`}
          onClick={loadMore}
          data-testid={'load-more'}
        />
        {removeFiltersLink}
      </div>
    </>
  )
}

export default LoadMore
