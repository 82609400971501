import { useState } from 'react'

import { Radio } from '@speedwaymotors/clutch/Atoms/Form/Elements'

import Accordion from 'Clutch/Molecules/Accordion'

import CoveringSpinner from 'Clutch/Atoms/CoveringSpinner'
import Skeleton from 'Clutch/Atoms/Skeleton'
import Typography from 'Clutch/Atoms/Typography'

import { setSidebarOpen } from '../../../Utilities/LocalStorage/sidebarDrawer'
import AppName from '../../../appName/appName'
import { usePrpStore } from '../../../contexts/prpContext'
import accordionStyles from '../shared/facetAccordion.module.scss'
import styles from './sortTypeFacet.module.scss'
import useProductResultPage from '/hooks/useProductResultPage'

const SortTypeFacet = () => {
  const [loading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const sortOrders = useProductResultPage((x) => x.sortOrders)

  const Navigate = usePrpStore((x) => x.Navigate)

  if (!sortOrders) {
    return <Skeleton height={40} width={250} />
  }

  const summary = (
    <Typography
      as={'h3'}
      font={'bold'}
      tone={'normal'}
      size={1}
      data-testid={`facetName_sort`}
    >
      Sort
    </Typography>
  )

  const getSortTypeOptions = () => {
    return sortOrders.map((x) => {
      return { label: x.title, value: x.title, checked: x.isSelected }
    })
  }

  function navigate(e) {
    setSidebarOpen()
    const found = sortOrders.find((x) => x.title == e.target.value)
    Navigate(e, found.url, setIsLoading)
  }

  const selectedSort = sortOrders.find((x) => x.isSelected)?.title || ''



  function handleAccordionClick() {
    setOpen(!open)
  }

  return (
    <Accordion
      summary={summary}
      defaultOpen={open}
      fill
      iconOpened={'chevron'}
      iconClosed={'chevron'}
      segmentAccordionName={`${AppName} Sort Type Accordion`}
      size={1}
      data-testid={'facet_sort'}
      className={accordionStyles[`accordionSvgOpen_${open}`]}
      onClick={handleAccordionClick}
    >
      <CoveringSpinner isLoading={loading} opacityWhenLoading={0.5}>
        <Radio
          id={'sortType_sidebar'}
          values={getSortTypeOptions()}
          className={styles.option}
          onChange={navigate}
          selectedValue={selectedSort}
        />
      </CoveringSpinner>
    </Accordion>
  )
}

export default SortTypeFacet
