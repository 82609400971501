import { useState } from 'react'

import Brands from 'Clutch/Constants/Brand'
import Intents from 'Clutch/Constants/Intent'

import Popover from 'Clutch/Components/Popover'

import Area from 'Clutch/Molecules/Area'

import Card from 'Clutch/Atoms/Card'
import Typography from 'Clutch/Atoms/Typography'

import styles from './productMatchScores.module.scss'

const ProductMatchScores = ({ scores, matchedFields }) => {

  const [openScore, setOpenScore] = useState(null)

  function handleToggleMenu(index) {
    return (isOpen) => {
      setOpenScore(isOpen === true ? index : null)
    }
  }

  function handleMatchedFieldsToggle(isOpen) {
    setOpenScore(isOpen === true ? scores.count : null)
  }

  if (!scores) {
    return false
  }

  try {
    return (
      <Card noMargin noPadding className={styles.card}>
        <div className={styles.scoresWrapper}>
          {scores.map((score, index) => {
            let brand
            let intent
            if (index < Intents.length - 1) {
              intent = Intents[index]
            } else if (index < Intents.length + Brands.length - 1) {
              brand = Brands[index]
            } else brand = Brands[0]

            return (
              <Popover
                key={`score_${score.field}_${index}`}
                content={<Typography size={1}>{score.field}</Typography>}
                isOpen={openScore === index}
                onToggleMenu={handleToggleMenu}
              >
                <Area
                  className={styles.score}
                  brand={brand}
                  intent={intent}
                  title={score.field}
                >
                  <Typography size={0.75}>{score.value}</Typography>
                </Area>
              </Popover>
            )
          })}
          {matchedFields.length > 0 ? (
            <Popover
              content={
                <div>
                  {matchedFields.map((field, index) => (
                    <Typography key={index}>{field}</Typography>
                  ))}
                </div>
              }
              isOpen={openScore === scores.count}
              onToggleMenu={handleMatchedFieldsToggle}
            >
              <Area
                className={styles.matchedFields}
                brand="primary"
                title={matchedFields.join('\n')}
              >
                <Typography size={0.75}>Matched</Typography>
              </Area>
            </Popover>
          ) : null}
        </div>
      </Card>
    )
  } catch (err) {
    console.error(err)
    return false
  }
}

export default ProductMatchScores
