import React, { useState } from 'react'

import Popover from 'Clutch/Components/Popover'

import Area from 'Clutch/Molecules/Area'

import Card from 'Clutch/Atoms/Card'
import Typography from 'Clutch/Atoms/Typography'

import styles from './facetRules.module.scss'

const FacetRules = ({ facetRules, sminetAddNewFacetRuleUrl }) => {
  const [isOpen, setIsOpen] = useState(false)
  const filteredFacetRules =
    facetRules?.filter(
      (rule) => rule.type !== 'Diversification' && rule.type !== 'None',
    ) || []
  const hasFacetRules = filteredFacetRules.length > 0
  const titleContent = hasFacetRules
    ? 'Facet Rules Present'
    : 'No Facet Rules Applied'

  const groupedRules = hasFacetRules
    ? {
        Exclude: filteredFacetRules.filter((rule) => rule.type === 'Exclude'),
        Include: filteredFacetRules.filter((rule) => rule.type === 'Include'),
        TopCategory: filteredFacetRules.filter(
          (rule) => rule.type === 'TopCategory',
        ),
      }
    : {}

  function handleMouseEnter() {
    setIsOpen(true)
  }

  function handleMouseLeave() {
    setIsOpen(false)
  }

  const getCurrentUrl = () => window.location.href

  function handleAddNewRule(e) {
    e.preventDefault()
    const currentUrl = new URL(getCurrentUrl())
    let newUrl = sminetAddNewFacetRuleUrl

    if (currentUrl.pathname.startsWith('/shop')) {
      const encodedPath = encodeURIComponent(currentUrl.pathname)
      newUrl = `${sminetAddNewFacetRuleUrl}?shopUrl=${encodedPath}`
    }

    window.open(newUrl, '_blank', 'noopener,noreferrer')
  }

  const renderRuleGroup = (rules, type, intent) =>
    rules.length > 0 && (
      <Area key={type} intent={intent} className={styles.ruleGroup}>
        <Typography size={1} className={styles.ruleGroupTitle}>
          <strong>{type}</strong>
        </Typography>
        <ul className={styles.ruleList}>
          {rules.map((rule) => (
            <li key={rule.id} className={styles.ruleItem}>
              <div className={styles.ruleInfo}>
                <Typography size={0.875} className={styles.ruleName}>
                  {rule.isLocalAttribute
                    ? 'Local Attribute'
                    : rule.facetTypeDisplayName || rule.facetType}
                  {rule.attributeName && `: ${rule.attributeName}`}
                </Typography>
                <Typography size={0.75} className={styles.ruleCount}>
                  {rule.type === 'TopCategory'
                    ? 'Top category rule applied'
                    : rule.facetIds?.length === 0 && rule.type === 'Exclude'
                      ? 'All options excluded'
                      : rule.facetIds?.length === 0 && rule.type === 'Include'
                        ? 'All options included'
                        : `${rule.facetIds?.length || 0} option${rule.facetIds?.length !== 1 ? 's' : ''} ${rule.type.toLowerCase()}d`}
                </Typography>
              </div>
              <a
                href={rule.sminetUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.configureLink}
              >
                Configure
              </a>
            </li>
          ))}
        </ul>
      </Area>
    )

  const areaContent = (
    <Area
      className={styles.rule}
      title={titleContent}
      intent={hasFacetRules ? 'positive' : undefined}
    >
      <Typography size={0.75}>{titleContent}</Typography>
    </Area>
  )

  const popoverContent = hasFacetRules ? (
    <div className={styles.popoverContent}>
      {renderRuleGroup(groupedRules.Exclude, 'Excluded', 'negative')}
      {renderRuleGroup(groupedRules.Include, 'Included', 'positive')}
      {renderRuleGroup(groupedRules.TopCategory, 'Top Category', 'action')}
    </div>
  ) : (
    <div className={styles.popoverContent}>
      <Typography size={0.875}>No facet rules applied.</Typography>
      <a href="#" onClick={handleAddNewRule} className={styles.addLink}>
        Add New Facet Rule
      </a>
    </div>
  )

  return (
    <Card noMargin noPadding className={styles.card}>
      <div
        className={styles.rulesWrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Popover content={popoverContent} isOpen={isOpen}>
          {areaContent}
        </Popover>
      </div>
    </Card>
  )
}

export default FacetRules
