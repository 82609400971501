import React, { useState } from 'react'

import Popover from 'Clutch/Components/Popover'

import Area from 'Clutch/Molecules/Area'

import Card from 'Clutch/Atoms/Card'
import Typography from 'Clutch/Atoms/Typography'

import styles from './productRecalls.module.scss'

const ProductRecalls = ({ productRecalls, sminetAddProductRecallUrl }) => {
  const [isOpen, setIsOpen] = useState(false)
  const hasProductRecalls = productRecalls && productRecalls.length > 0
  const titleContent = hasProductRecalls
    ? 'Product Recalls Present'
    : 'No Product Recalls Applied'

  function handleMouseEnter() {
    setIsOpen(true)
  }

  function handleMouseLeave() {
    setIsOpen(false)
  }

  const renderProductRecalls = () => (
    <ul className={styles.recallList}>
      {productRecalls.map((recall) => (
        <li key={recall.searchRecallId} className={styles.recallItem}>
          <div className={styles.recallInfo}>
            <Typography size={0.875} className={styles.recallName}>
              SKU: {recall.sopSkuBase}
            </Typography>
            <Typography size={0.75} className={styles.recallDetails}>
              Recall ID: {recall.searchRecallId}
            </Typography>
            {recall.query && (
              <Typography size={0.75} className={styles.recallDetails}>
                Query: {recall.query}
              </Typography>
            )}
            {recall.url && (
              <Typography size={0.75} className={styles.recallDetails}>
                URL: {recall.url}
              </Typography>
            )}
            {recall.boostingPosition !== null && (
              <Typography size={0.75} className={styles.recallDetails}>
                Boosting Position: {recall.boostingPosition}
              </Typography>
            )}
            <Typography size={0.75} className={styles.recallDetails}>
              Enabled: {recall.isEnabled ? 'Yes' : 'No'}
            </Typography>
          </div>
          <a
            href={recall.sminetUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.configureLink}
          >
            Configure
          </a>
        </li>
      ))}
    </ul>
  )

  const areaContent = (
    <Area
      className={styles.recall}
      title={titleContent}
      intent={hasProductRecalls ? 'positive' : undefined}
    >
      <Typography size={0.75}>{titleContent}</Typography>
    </Area>
  )

  const popoverContent = hasProductRecalls ? (
    <div className={styles.popoverContent}>
      <Typography size={1} className={styles.recallGroupTitle}>
        <strong>Product Recalls</strong>
      </Typography>
      {renderProductRecalls()}
    </div>
  ) : (
    <div className={styles.popoverContent}>
      <Typography size={0.875}>No product recalls applied.</Typography>
      <a
        href={sminetAddProductRecallUrl}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.addLink}
      >
        Add New Product Recall
      </a>
    </div>
  )

  return (
    <Card noMargin noPadding className={styles.card}>
      <div
        className={styles.recallsWrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Popover content={popoverContent} isOpen={isOpen}>
          {areaContent}
        </Popover>
      </div>
    </Card>
  )
}

export default ProductRecalls
