import React, { useContext, useEffect, useRef, useMemo } from 'react'

import { RecommendationProvider } from 'Clutch/Stores/RecommendationStore/RecommendationStore'
import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'

import { AddToCartExperienceContext } from 'Contexts/AddToCartExperienceContext/AddToCartExperienceContext'

import Modal from 'Components/Modal/ModalWithPortal.js'

import RecommendationSection from 'Clutch/Organisms/RecommendationSection/RecommendationSection'

import { usePrpStore } from '../../contexts/prpContext'
import useProductResultPage from '../../hooks/useProductResultPage'
import styles from './compareToolModal.module.scss'

const CompareToolModal = () => {
  const modal = useRef(null)
  const compareToolModal = usePrpStore((x) => x.uiState.compareToolModal)
  const CloseCompareToolModal = usePrpStore((x) => x.CloseCompareToolModal)

  useEffect(() => {
    if (compareToolModal) {
      modal.current.open()
    }
  }, [compareToolModal])

  const {
    isVariantDetailsDrawerOpen,
    isKitDetailsDrawerOpen,
    isAtcDrawerOpen,
  } = useContext(AddToCartExperienceContext)

  useEffect(() => {
    if (
      isVariantDetailsDrawerOpen ||
      isKitDetailsDrawerOpen ||
      isAtcDrawerOpen
    ) {
      modal.current.close()
      CloseCompareToolModal()
    }
  }, [isVariantDetailsDrawerOpen, isKitDetailsDrawerOpen, isAtcDrawerOpen])

  return (
    <div>
      <Modal
        ref={modal}
        outsideClick
        id="compareToolModal"
        customOnClose={CloseCompareToolModal}
      >
        <CompareToolContent />
      </Modal>
    </div>
  )
}

const CompareToolContent = () => {
  const skuBaseToCompare = useProductResultPage((x) => x.skuBaseToCompare)

  const sections = useMemo(() => ['PRP_Compare'], []);
  const skuBaseNumbers = useMemo(() => (skuBaseToCompare ? [skuBaseToCompare] : []), [skuBaseToCompare]);

  return (
    <ErrorBoundary>
      <div className={styles.compareToolWrapper}>
        <RecommendationProvider
          sections={sections}
          skuBaseNumbers={skuBaseNumbers}
        >
          <RecommendationSection
            section={'PRP_Compare'}
            displayType={'Comparison'}
            numberOfSkeletons={4}
            numberOfSkeletonSections={1}
            skeletonType={'comparison'}
            displayNoRecMessage
          />
        </RecommendationProvider>
      </div>
    </ErrorBoundary>
  )
}

export default CompareToolModal
