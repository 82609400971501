import React, { useContext, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'

import RecommendationsCarousel from 'Clutch/Containers/RecommendationsCarousel'
import { useUserStore } from 'Clutch/Stores/UserStore/UserStore'
import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'

import { useLazyFeatureFlag } from 'Clutch/Hooks/useFeatureFlag/useFeatureFlag'

import Skeleton from 'Clutch/Atoms/Skeleton'

import useProductResultPage from '../../hooks/useProductResultPage'
import styles from './campaign.module.scss'

const ArticleSearchCampaign = () => {
  return (
    <ErrorBoundary>
      <ArticleSearchCampaignInternal />
    </ErrorBoundary>
  )
}

const ArticleSearchCampaignInternal = () => {
  const articleDetails = useProductResultPage(
    (x) => x.articleSearchResponse?.articleDetails,
  )
  const isBot = useUserStore((x) => x.context.isBot)
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: '200px 0px',
  })

  if (!articleDetails?.length) {
    return false
  }
  if (typeof window == 'undefined' || isBot()) {
    return <ArticleSearchRack articles={articleDetails} />
  }
  if (!inView) {
    return <span ref={ref}></span>
  }
  return <ArticleSearchRack articles={articleDetails} />
}

export default ArticleSearchCampaign

const ArticleSearchRack = ({ articles }) => {
  const recBlock = useMemo(() => ({
    recommendations: articles.map((x) => {
      return { articleId: x.id, articleDetails: x }
    }),
    useBorders: true,
    title: `Related Articles`,
    sectionType: 'Article Search',
  }), [articles])
  return (
    <div className={styles.articles}>
      <RecommendationsCarousel
        leftAlignCarousel
        showCarouselGradient
        extendGuttersForCarousel
        recBlock={recBlock}
      />
    </div>
  )
}
