import { useEffect, useState, useMemo } from 'react'

import ErrorBoundary from 'Clutch/UtilityComponents/ErrorBoundary'
import {
  RecommendationProvider,
  useRecommendationStore,
} from 'Stores/RecommendationStore/RecommendationStore'

import defer from 'Utilities/defer'

import RecommendationSection from 'Clutch/Organisms/RecommendationSection'

import useProductResultPage from '../../hooks/useProductResultPage'
import { usePrpStore } from '/contexts/prpContext'

const Recommendations = ({ sectionName = null }) => {
  const skeleton = useProductResultPage((x) => x.skeleton)
  const isNoResultPage = useProductResultPage((x) => x.isNoResultPage)
  const displayTitle = useProductResultPage((x) => x.displayTitle)
  const searchProducts = useProductResultPage((x) => x.searchProducts)
  const spaLoading = usePrpStore((x) => x.uiState.spaLoading)
  const sidebarDrawerOpen = usePrpStore((x) => x.uiState.sidebarDrawerOpen)
  const [skuBasesToSend, setSkuBasesToSend] = useState([])

  useEffect(() => {
    // defer updating recommendations until the sidebar is closed
    if (!sidebarDrawerOpen) {
      defer(() => {
        setSkuBasesToSend(
          (searchProducts ?? []).map((product) => product.skuBaseNumber),
        )
      })
    }
  }, [searchProducts, sidebarDrawerOpen])

  const defaultSectionName = isNoResultPage ? 'NRP' : 'PRP_Bottom'
  const actualSectionName = sectionName ?? defaultSectionName
  const actualSectionNameArray = useMemo(() => [actualSectionName], [actualSectionName])
  
  return (
    <div>
      <ErrorBoundary>
        <RecommendationProvider
          sections={actualSectionNameArray}
          keywords={displayTitle}
          isPageLoading={skeleton || spaLoading}
          skuBaseNumbers={skuBasesToSend}
        >
          <RecommendationsInner
            sectionName={actualSectionName}
            displayTitle={displayTitle}
            skeleton={skeleton}
            spaLoading={spaLoading}
          />
        </RecommendationProvider>
      </ErrorBoundary>
    </div>
  )
}

const RecommendationsInner = ({
  sectionName,
  displayTitle,
  skeleton,
  spaLoading,
}) => {
  const setIsPageLoading = useRecommendationStore((x) => x.setIsPageLoading)
  const setSections = useRecommendationStore((x) => x.setSections)

  useEffect(() => {
    setSections([sectionName])
  }, [sectionName])

  useEffect(() => {
    setIsPageLoading(skeleton || spaLoading)
  }, [skeleton, spaLoading])

  return (
    <RecommendationSection
      extendGuttersForCarousel
      section={sectionName}
      numberOfSkeletons={3}
      numberOfSkeletonSections={2}
      skeletonDisplayType={'Carousel'}
      displayType={'Carousel'}
      spacingSize={'Jumbo-SM'}
      keywords={displayTitle}
      leftAlignCarousel
    />
  )
}

export default Recommendations
