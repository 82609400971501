import React, { useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import getFitmentDetailsFromSelectedFacets from 'Clutch/Utilities/CustomerProjectUtilities/getFitmentDetailsFromSelectedFacets'
import { createClickedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents'
import Retry from 'Clutch/Utilities/Retry'

import Button from 'Clutch/Molecules/Button'

import AutoComplete from 'Atoms/Form/Elements/AutoComplete/AutoComplete'
import Card from 'Clutch/Atoms/Card'
import CoveringSpinner from 'Clutch/Atoms/CoveringSpinner'
import Divider from 'Clutch/Atoms/Divider'
import Svg from 'Clutch/Atoms/Svg'
import Typography from 'Clutch/Atoms/Typography'

import useProductResultPage from '../../hooks/useProductResultPage'
import useVehicleSidebar from '../../hooks/useVehicleSidebar'
import styles from './incompleteYmmSelector.module.scss'
import { usePrpStore } from '/contexts/prpContext'

const IncompleteYmmSelector = () => {
  const [remainingYmmOptions, setRemainingYmmOptions] = useState([])
  const [selectedRemainingYmmOptions, setSelectedRemainingYmmOptions] =
    useState({})
  const [ymmCompletionLoading, setYmmCompletionLoading] = useState(false)
  const [navUrl, setNavUrl] = useState(false)

  const selectedFacetOptions = useProductResultPage(
    (x) => x.selectedFacetOptions,
  )
  const searchResponseFacets = useProductResultPage(
    (x) => x.searchResponseFacets,
  )
  const spaLoadId = useProductResultPage((x) => x.spaLoadId)
  const engineFitmentPageTitle = useProductResultPage(
    (x) => x.engineFitmentPageTitle,
  )
  const currentPageLinkRequestJson = useProductResultPage(
    (x) => x.currentPageLinkRequestJson,
  )
  const selectedFacets = selectedFacetOptions || []
  const fitmentDetails =
    getFitmentDetailsFromSelectedFacets(selectedFacets) || {}
  const { vehicleName } = useVehicleSidebar(
    selectedFacets,
    fitmentDetails,
    engineFitmentPageTitle,
  )

  const Navigate = usePrpStore((x) => x.Navigate)

  const yearRef = useRef()
  const modelRef = useRef()

  useEffect(() => {
    const make = {
      id: selectedFacetOptions?.find((x) => x.fitmentDetails?.make != null)
        ?.fitmentDetails?.makeId,
      name: selectedFacetOptions?.find((x) => x.fitmentDetails?.make != null)
        ?.fitmentDetails?.make,
    }
    const model = {
      id: selectedFacetOptions?.find((x) => x.fitmentDetails?.model != null)
        ?.fitmentDetails?.modelId,
      name: selectedFacetOptions?.find((x) => x.fitmentDetails?.model != null)
        ?.fitmentDetails?.model,
    }
    setSelectedRemainingYmmOptions({ make: make, model: model })
    setNavUrl(false)
  }, [spaLoadId])

  useEffect(() => {
    if (
      yearRef?.current &&
      selectedRemainingYmmOptions?.make &&
      !selectedRemainingYmmOptions?.year &&
      remainingYmmOptions?.length
    ) {
      yearRef.current.focus()
    }

    if (
      modelRef?.current &&
      selectedRemainingYmmOptions?.year &&
      selectedRemainingYmmOptions?.make &&
      !selectedRemainingYmmOptions?.model &&
      remainingYmmOptions?.length
    ) {
      modelRef.current.focus()
    }
  }, [selectedRemainingYmmOptions, remainingYmmOptions?.length])

  useEffect(() => {
    GetYearMakeModelData()

    async function GetYearMakeModelData() {
      const remainingYmmFacets = searchResponseFacets?.filter(
        (x) => x.facetName == 'Model' || x.facetName == 'Year',
      )
      const makeName = selectedFacetOptions?.find(
        (x) => x.fitmentDetails?.make != null,
      )?.fitmentDetails?.make
      const makeId = selectedFacetOptions?.find(
        (x) => x.fitmentDetails?.makeId != null,
      )?.fitmentDetails?.makeId
      const modelId = selectedFacetOptions?.find(
        (x) => x.fitmentDetails?.modelId != null,
      )?.fitmentDetails?.modelId

      var vehicle_module = await Retry(
        () => import('Contexts/VehicleContext/CoreVehicleRequests'),
      )
      var makeResponse =
        makeName && (await vehicle_module.FetchMakes('/graphql/trait'))
      var modelResponse = makeName
        ? await vehicle_module.FetchModels(makeName, '/graphql/trait')
        : false
      var yearResponse = remainingYmmFacets?.find((x) => x.facetName == 'Year')
        ? remainingYmmFacets?.find((x) => x.facetName == 'Year')
        : await vehicle_module.FetchYearMakeModel(
            {},
            undefined,
            makeId,
            modelId,
            '/graphql/trait',
          )

      setRemainingYmmOptions([
        remainingYmmFacets?.find((x) => x.facetName == 'Year')
          ? {
              name: 'Year',
              options: remainingYmmFacets
                ?.find((x) => x.facetName == 'Year')
                ?.searchResponseFacetOptions?.map((y) => {
                  return {
                    value: JSON.stringify({ id: y.id, name: y.displayValue }),
                    label: y.displayValue,
                  }
                }),
            }
          : {
              name: 'Year',
              options: yearResponse?.ymmData?.years?.map((y) => {
                return {
                  value: JSON.stringify({
                    id: y.optionId,
                    name: y.displayName,
                  }),
                  label: y.displayName,
                }
              }),
            },
        {
          name: 'Make',
          options: makeResponse?.allMakes?.map((x) => {
            return {
              label: x.make,
              value: JSON.stringify({ id: x.makeId, name: x.make }),
            }
          }),
        },
        remainingYmmFacets?.find((x) => x.facetName == 'Model')
          ? {
              name: 'Model',
              options: remainingYmmFacets
                ?.find((x) => x.facetName == 'Model')
                ?.searchResponseFacetOptions?.map((y) => {
                  return {
                    value: JSON.stringify({ id: y.id, name: y.displayValue }),
                    label: y.displayValue,
                  }
                }),
            }
          : {
              name: 'Model',
              options: modelResponse?.models
                ?.find((x) => x.makeName == makeName)
                ?.models?.map((x) => {
                  return {
                    label: x.modelName,
                    value: JSON.stringify({
                      id: x.modelId,
                      name: x.modelName,
                    }),
                  }
                }),
            },
      ])

      const make = {
        id: selectedFacetOptions?.find((x) => x.fitmentDetails?.make != null)
          ?.fitmentDetails?.makeId,
        name: selectedFacetOptions?.find((x) => x.fitmentDetails?.make != null)
          ?.fitmentDetails?.make,
      }
      const model = {
        id: selectedFacetOptions?.find((x) => x.fitmentDetails?.model != null)
          ?.fitmentDetails?.modelId,
        name: selectedFacetOptions?.find((x) => x.fitmentDetails?.model != null)
          ?.fitmentDetails?.model,
      }
      if (model.name != null) {
        const selectedOptions = {
          make: make,
          model: model,
        }
        setSelectedRemainingYmmOptions(selectedOptions)
      } else {
        setSelectedRemainingYmmOptions({ make: make })
      }
    }
  }, [selectedFacetOptions])

  const fitmentFacet = selectedFacetOptions?.find(
    (x) => x.displayText == 'Fitment',
  )

  const makeId = selectedFacetOptions?.find(
    (x) => x.fitmentDetails?.makeId != null,
  )?.fitmentDetails?.makeId
  const year = selectedFacetOptions?.find((x) => x.fitmentDetails?.year != null)
    ?.fitmentDetails?.year

  async function GetUrlAndNavigate(e, selectedOptions) {
    const linkRequest = JSON.parse(currentPageLinkRequestJson)
    const linkRequests = [
      {
        ...linkRequest,
        year: selectedOptions?.year?.id || linkRequest?.year,
        make: selectedOptions?.make?.id || linkRequest?.make,
        model: selectedOptions.model?.id || linkRequest?.model,
      },
    ]
    const utility_module = await Retry(() => import('/Utilities/buildLinks'))
    var response = await utility_module.BuildLinks(linkRequests)

    if (response?.length) {
      const link = response[0]
      if (link.isValid) {
        setNavUrl(link.url)
        Navigate(e, link.url, setYmmCompletionLoading)
      } else {
        //nav to ymm + search without offending facets
        const sanitizedLinkRequests = [
          {
            year: selectedOptions?.year?.id || linkRequest?.year,
            make: selectedOptions?.make?.id || linkRequest?.make,
            model: selectedOptions.model?.id || linkRequest?.model,
            searchQuery: linkRequest?.searchQuery,
          },
        ]

        var sanitizedResponse = await utility_module.BuildLinks(
          sanitizedLinkRequests,
        )

        if (sanitizedResponse?.length) {
          const sanitizedLink = sanitizedResponse[0]
          if (sanitizedLink.isValid) {
            setNavUrl(sanitizedLink.url)
            Navigate(e, sanitizedLink.url, setYmmCompletionLoading)
          }
        }
      }
    }
  }

  function handleOnChangeModel(e) {
    try {
      const selection = JSON.parse(e.value)
      const selectedOptions = {
        ...selectedRemainingYmmOptions,
        model: selection,
      }
      setSelectedRemainingYmmOptions({
        ...selectedRemainingYmmOptions,
        model: selection,
      })

      if (year || selectedOptions.year) {
        GetUrlAndNavigate(e, selectedOptions)
      } else {
        GetUpdatedVehicles()

        async function GetUpdatedVehicles() {
          var vehicle_module = await Retry(
            () => import('Contexts/VehicleContext/CoreVehicleRequests')
          )
          var response = await vehicle_module.FetchYearMakeModel(
            {},
            undefined,
            makeId,
            selection.id,
            '/graphql/trait'
          )

          setRemainingYmmOptions([
            ...remainingYmmOptions.filter((x) => x.name != 'Year'),
            {
              name: 'Year',
              options: response?.ymmData?.years.map((x) => {
                return {
                  label: x.displayName,
                  id: x.optionId,
                  value: JSON.stringify({
                    id: x.optionId,
                    name: x.displayName,
                  }),
                }
              }),
            },
          ])
        }
      }
    } catch (err) {
      console.error(
        'There was an error selecting a model on incomplete ymm page',
        err
      )
    }
  }

  function handleOnChangeYear(e) {
    try {
      const selection = JSON.parse(e.value)
      const selectedOptions = {
        ...selectedRemainingYmmOptions,
        year: selection,
      }
      setSelectedRemainingYmmOptions(selectedOptions)

      const selectorMakeId = selectedRemainingYmmOptions?.make?.id

      if (selectedOptions.model?.name) {
        GetUrlAndNavigate(e, selectedOptions)
      } else {
        GetUpdatedVehicles()

        async function GetUpdatedVehicles() {
          var vehicle_module = await Retry(
            () => import('Contexts/VehicleContext/CoreVehicleRequests')
          )
          var response = await vehicle_module.FetchYearMakeModel(
            {},
            selection.id,
            selectorMakeId,
            undefined,
            '/graphql/trait'
          )

          setRemainingYmmOptions([
            ...remainingYmmOptions.filter((x) => x.name != 'Model'),
            {
              name: 'Model',
              options: response?.ymmData?.models.map((x) => {
                return {
                  label: x.displayName,
                  id: x.optionId,
                  value: JSON.stringify({
                    id: x.optionId,
                    name: x.displayName,
                  }),
                }
              }),
            },
          ])
        }
      }
    } catch (err) {
      console.error('There was an error completing ymm selection', err)
    }
  }

  function handleOnChangeMake(e) {
    try {
      const selection = JSON.parse(e.value)
      const selectedOptions = {
        ...selectedRemainingYmmOptions,
        make: selection,
        model: {},
      }
      setSelectedRemainingYmmOptions(selectedOptions)
      GetUpdatedVehicles()

      const selectorYearId = selectedRemainingYmmOptions?.year?.id

      async function GetUpdatedVehicles() {
        var vehicle_module = await Retry(
          () => import('Contexts/VehicleContext/CoreVehicleRequests')
        )
        if (selectedOptions.year?.id) {
          var response = await vehicle_module.FetchYearMakeModel(
            {},
            selectorYearId,
            selection.id,
            undefined,
            '/graphql/trait'
          )

          setRemainingYmmOptions([
            ...remainingYmmOptions.filter((x) => x.name != 'Model'),
            {
              name: 'Model',
              options: response?.ymmData?.models.map((x) => {
                return {
                  label: x.displayName,
                  id: x.optionId,
                  value: JSON.stringify({
                    id: x.optionId,
                    name: x.displayName,
                  }),
                }
              }),
            },
          ])
        } else {
          var modelResponse = await vehicle_module.FetchModels(
            selection.name,
            '/graphql/trait'
          )

          setRemainingYmmOptions([
            ...remainingYmmOptions.filter((x) => x.name != 'Model'),
            {
              name: 'Model',
              options: modelResponse?.models
                ?.find((x) => x.makeName == selection.name)
                ?.models?.map((x) => {
                  return {
                    label: x.modelName,
                    id: x.modelId,
                    value: JSON.stringify({
                      id: x.modelId,
                      name: x.modelName,
                    }),
                  }
                }),
            },
          ])
        }
      }
    } catch (err) {
      console.error('There was an error completing ymm selection', err)
    }
  }

  const findAndSortTheOptions = (name, remainingYmmOptions) => {
    if (name == 'Year')
      return remainingYmmOptions
        .find((x) => x.name == name)
        ?.options?.sort((a, b) => b.label.localeCompare(a.label))
    return remainingYmmOptions
      .find((x) => x.name == name)
      ?.options?.sort((a, b) => a.label.localeCompare(b.label))
  }

  const yearOptions = findAndSortTheOptions('Year', remainingYmmOptions)
  const makeOptions = findAndSortTheOptions('Make', remainingYmmOptions)
  const modelOptions = findAndSortTheOptions('Model', remainingYmmOptions)

  return (
    <Card
      fill
      noPadding
      layer={1}
      className={classNames(styles.card, styles.showMobile)}
    >
      <div className={styles.cardContents}>
        <div className={styles.title}>
          <Svg icon={'car'} size={1.25} className={styles.svg} />
          <Typography className={styles.exactFitText}>
            Get an exact fit for your{' '}
          </Typography>
          <Typography className={styles.vehicleText} font={'bold'}>
            {vehicleName}
          </Typography>
        </div>
        <>
          <Divider className={styles.divider} />
          <div className={styles.completeYourVehicle}>
            <CoveringSpinner
              isLoading={ymmCompletionLoading}
              fillWidth={true}
              className={styles.completeVehicleSelectors}
            >
              <div className={styles.selectWrapper}>
                <AutoComplete
                  options={yearOptions}
                  placeholder={
                    selectedRemainingYmmOptions.year?.name ??
                    fitmentFacet?.fitmentDetails?.year ??
                    'Choose Year'
                  }
                  onChange={handleOnChangeYear}
                  label={'Year'}
                  ref={yearRef}
                  isDisabled={!yearOptions?.length}
                />
              </div>
              <div className={styles.selectWrapper}>
                <AutoComplete
                  options={makeOptions}
                  placeholder={
                    selectedRemainingYmmOptions.make?.name ??
                    fitmentFacet?.fitmentDetails?.make ??
                    'Choose Make'
                  }
                  onChange={handleOnChangeMake}
                  label={'Make'}
                  isDisabled={!makeOptions?.length}
                />
              </div>
              <div className={styles.selectWrapper}>
                <AutoComplete
                  options={modelOptions}
                  placeholder={
                    selectedRemainingYmmOptions.model?.name ??
                    fitmentFacet?.fitmentDetails?.model ??
                    'Choose Model'
                  }
                  onChange={handleOnChangeModel}
                  label={'Model'}
                  ref={modelRef}
                  isDisabled={!modelOptions?.length}
                />
              </div>
              <Button
                intent={'action'}
                size={'small'}
                className={styles.viewResults}
                href={navUrl ?? '/'}
                disabled={!navUrl}
                testingName={'PrpViewVehicleResults'}
                segmentEvent={createClickedSegmentEvent(
                  'PrpViewVehicleResults',
                )}
              >
                <Typography className={styles.nowrap} font={'bold'}>
                  View Results
                </Typography>
              </Button>
            </CoveringSpinner>
          </div>
        </>
      </div>
    </Card>
  )
}

export default IncompleteYmmSelector
