import { useContext, useState } from 'react'

import { useVehicleStore } from 'Clutch/Stores/VehicleStore'
import ClassNames from 'classnames'

import {
  addToGarage,
  showAddToGarage,
  showUpdateCustomerProject,
  updateCustomerProject,
} from 'Clutch/Utilities/CustomerProjectUtilities/customerProjectUtilities'
import getFitmentDetailsFromSelectedFacets from 'Clutch/Utilities/CustomerProjectUtilities/getFitmentDetailsFromSelectedFacets'
import { createClickedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents'

import { NavigationContext } from 'Clutch/Contexts/Navigation/NavigationContext'
import { NotificationContext } from 'Clutch/Contexts/NotificationContext/NotificationContext'

import Button from 'Clutch/Molecules/Button'

import Svg from 'Clutch/Atoms/Svg'
import Typography from 'Clutch/Atoms/Typography'

import useProductResultPage from '../../hooks/useProductResultPage'
import useVehicleSidebar from '../../hooks/useVehicleSidebar'
import styles from './stickyHeader.module.scss'
import StickyHeaderSkeleton from './stickyHeaderSkeleton'
import { usePrpStore } from '/contexts/prpContext'

const StickyHeaderContent = ({ classNames }) => {
  const [loading, setLoading] = useState(false)

  const customerProjects = useVehicleStore((x) => x.context.customerProjects)
  const skeleton = useProductResultPage((x) => x.skeleton)
  const isNoResultPage = useProductResultPage((x) => x.isNoResultPage)
  const isFacetResultPage = useProductResultPage((x) => x.isFacetResultPage)
  const selectedFacetOptions = useProductResultPage(
    (x) => x.selectedFacetOptions,
  )
  const engineFitmentPageTitle = useProductResultPage(
    (x) => x.engineFitmentPageTitle,
  )
  const fitmentDetails =
    getFitmentDetailsFromSelectedFacets(selectedFacetOptions) || {}
  const { vehicleName, isVehicleSelected } = useVehicleSidebar(
    selectedFacetOptions,
    fitmentDetails,
    engineFitmentPageTitle,
  )

  const canAdd = showAddToGarage(customerProjects, fitmentDetails)
  const canUpdate = showUpdateCustomerProject(customerProjects, fitmentDetails)

  const { dispatch: navigationDispatch } = useContext(NavigationContext)
  const OpenSidebarDrawer = usePrpStore((x) => x.OpenSidebarDrawer)
  const selectCustomerProject = useVehicleStore(
    (state) => state.selectCustomerProject,
  )
  const addCustomerProject = useVehicleStore((x) => x.addCustomerProject)
  const updateCustomerProjectData = useVehicleStore(
    (x) => x.updateCustomerProjectData,
  )
  const { PushNotification } = useContext(NotificationContext)

  function handleSortAndFilterClick() {
    OpenSidebarDrawer()
  }

  function handleSelectVehicleOrEngine() {
    navigationDispatch({
      type: 'setCustomerProjectDrawerState',
      payload: {
        garageState: {
          isCustomerProjectDrawerOpen: true,
        },
      },
    })
  }

  function handleUpdateGarage() {
    updateCustomerProject(
      fitmentDetails,
      canUpdate,
      setLoading,
      selectCustomerProject,
      updateCustomerProjectData,
      PushNotification,
      vehicleName
    )
  }

  function handleAddToGarage() {
    addToGarage(
      fitmentDetails,
      setLoading,
      selectCustomerProject,
      addCustomerProject,
      PushNotification,
      vehicleName
    )
  }

  if (skeleton) {
    return <StickyHeaderSkeleton />
  }
  if (isNoResultPage) {
    return false
  }

  return (
    <div
      className={ClassNames(
        styles.stickyHeaderContainer,
        classNames,
        isFacetResultPage ? styles.forFrp : false,
      )}
    >
      <Button
        brand={'primary'}
        size={'small'}
        className={styles.stickyHeaderButton}
        segmentEvent={createClickedSegmentEvent('PrpStickyHeaderSortAndFilter')}
        testingName={'filterAndSortStickyHeader'}
        onClick={OpenSidebarDrawer}
      >
        <Svg icon="filter-sort" size={1.25} className={styles.filterIcon}></Svg>
        <Typography size={0.875} font={'bold'}>{`Sort & Filter`}</Typography>
      </Button>
      {!isVehicleSelected && isFacetResultPage && (
        <Button
          brand={'secondary'}
          size={'small'}
          className={styles.stickyHeaderButton}
          segmentEvent={createClickedSegmentEvent('SelectVehicleStickyHeader')}
          testingName={'selectVehicleStickyHeader'}
          onClick={handleSelectVehicleOrEngine}
        >
          <Typography size={0.875} font={'bold'}>
            {'Select Vehicle/Engine'}
          </Typography>
        </Button>
      )}
      {canUpdate && (
        <Button
          isWaiting={loading}
          brand={'secondary'}
          size={'small'}
          className={styles.stickyHeaderButton}
          segmentEvent={createClickedSegmentEvent('UpdateGarageStickyHeader')}
          testingName={'updateGarageStickyHeader'}
          onClick={handleUpdateGarage}
        >
          <Typography size={0.875} font={'bold'}>
            Update Garage
          </Typography>
        </Button>
      )}
      {!canUpdate && canAdd && (
        <Button
          isWaiting={loading}
          brand={'secondary'}
          size={'small'}
          className={styles.stickyHeaderButton}
          segmentEvent={createClickedSegmentEvent('AddToGarageStickyHeader')}
          testingName={'addVehicleStickyHeader'}
          onClick={handleAddToGarage}
        >
          <Typography size={0.875} font={'bold'}>
            Add to Garage
          </Typography>
        </Button>
      )}
    </div>
  )
}

export default StickyHeaderContent
