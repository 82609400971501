import { useEffect, useRef, useState, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'

import {
  useFeatureFlag,
  useLazyFeatureFlag,
} from '@speedwaymotors/clutch/Hooks/useFeatureFlag/useFeatureFlag'
import classNames from 'classnames'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { createClickedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents'

import useSegment from 'Clutch/Hooks/useSegment'
import useLogRocket from 'Hooks/useLogRocket'

import ItemsExpander from 'Clutch/Organisms/Expander/ItemsExpander'

import Card from 'Clutch/Atoms/Card'
import CoveringSpinner from 'Clutch/Atoms/CoveringSpinner'
import Typography from 'Clutch/Atoms/Typography'

import engineFitmentFacetTypes from '../../constants/engineFitmentFacetTypes'
import { usePrpStore } from '../../contexts/prpContext'
import useProductResultPage from '../../hooks/useProductResultPage'
import CriticalAttributes from '../criticalAttributes/criticalAttributes'
import styles from './categories.module.scss'

const emptyArray = []

const Categories = () => {
  const { ref } = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: '1000px 0px 1000px 0px',
  })
  const incompleteYmmPage = useProductResultPage((x) => x.incompleteYmmPage)
  const isSearchPage = useProductResultPage((x) => x.isSearchPage)
  const searchResponseFacets = useProductResultPage(
    (x) => x.searchResponseFacets,
  )
  const [loading, setLoading] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [clone, setClone] = useState(null)
  const spaEnabled = useFeatureFlag('PRP_SPA')
  const topCategoryScrollRef = useRef(null)
  const categoryFacet = searchResponseFacets.find(
    (facet) => facet.isForTopCategories,
  )
  const removed = useLazyFeatureFlag(
    isSearchPage && categoryFacet !== 'undefined'
      ? 'PRP_Remove_Top_Cats_On_Search'
      : 'placeholderdontdelete',
  )

  const suggestedFacets = searchResponseFacets
    .flatMap((facet) =>
      (facet.searchResponseFacetOptions || []).map((option) => ({
        ...option,
        facetType: facet.facetType,
      })),
    )
    .filter((option) => option.isSuggestedFacet)

  const campaign = useProductResultPage((x) => x.campaign)
  const suggestedFacetPresentRef = useRef(false)
  const { logRocketTrack } = useLogRocket()

  useEffect(() => {
    if ((!clone || spaEnabled) && categoryFacet) {
      setClone(
        typeof structuredClone !== 'undefined'
          ? structuredClone(categoryFacet?.searchResponseFacetOptions)
          : JSON.parse(
              JSON.stringify(categoryFacet?.searchResponseFacetOptions),
            ),
      )
    }
  }, [categoryFacet])

  useEffect(() => {
    if (!suggestedFacetPresentRef.current && suggestedFacets.length > 0) {
      logRocketTrack(`SuggestedFacetsPresent`)

      suggestedFacetPresentRef.current = true
    }
  }, [suggestedFacets])

  const allCampaignsAreImbeded =
    !campaign?.campaignPositions?.campaignCardPositions.some((x) => x === 0) &&
    !campaign?.campaignPositions?.campaignPositions.some((x) => x === 0) &&
    !campaign?.assets?.some((x) => x.placement === 'top')

  if (
    !categoryFacet &&
    ((campaign && !allCampaignsAreImbeded) || suggestedFacets.length === 0)
  ) {
    return null
  }

  const hideMobile = incompleteYmmPage

  if (!categoryFacet && !suggestedFacets) {
    return null
  }

  if (removed !== false && !suggestedFacets) {
    return false
  }

  if (categoryFacet?.isCriticalAttribute) return <CriticalAttributes />

  const anyCategoryImages = (
    clone || categoryFacet?.searchResponseFacetOptions
  )?.some(
    (option) =>
      option.thumbnailImageUrl &&
      categoryFacet?.shouldUseImageUrlForTopCategories,
  )

  const anySuggestedFacetImages = (clone || suggestedFacets).some(
    (option) => option.thumbnailImageUrl && option.isSuggestedFacet,
  )

  if (suggestedFacets.length > 0) {
    ;(clone || suggestedFacets).some(
      (option) => option.thumbnailImageUrl && option.isSuggestedFacet,
    )
  }

  function handleCallback(childData) {
    setExpanded(childData)
  }

  const longestValue = Math.max(
    ...(categoryFacet?.searchResponseFacetOptions?.map(
      (x) => x.displayValue.length,
    ) || []),
  )

  let items = clone || categoryFacet?.searchResponseFacetOptions

  if (suggestedFacets) {
    items = clone || suggestedFacets
  }

  const orderByAlphaNumeric =
    categoryFacet?.facetName === 'Displacement' ||
    categoryFacet?.facetName === 'Engine Version' ||
    categoryFacet?.facetName === 'Engine Code'

  if (categoryFacet && orderByAlphaNumeric)
    items = items.sort((a, b) => a.orderedSortIndex - b.orderedSortIndex)
  else if (suggestedFacets) {
    items.sort((a, b) => a.suggestionSortIndex - b.suggestionSortIndex)
  }

  if (anyCategoryImages || anySuggestedFacetImages) {
    items = items?.slice(0, 18)
  }

  const isNewEngineFacet = engineFitmentFacetTypes.includes(
    categoryFacet?.facetName,
  )


  return (
    <>
      <div ref={ref}></div>
      <div
        className={classNames(
          styles.topCategoriesMobileContainer,
          !anyCategoryImages && !anySuggestedFacetImages
            ? styles.noImagesMobileContainer
            : '',
          hideMobile ? styles.hideMobile : styles.showMobile,
        )}
      >
        {categoryFacet?.isCriticalAttribute ||
        categoryFacet?.showTopCategoryTitle ? (
          <Typography
            tone={'contrast'}
            size={1}
            font={'bold'}
            className={styles.topCategoryTitle}
          >
            {categoryFacet?.facetName}
          </Typography>
        ) : (
          false
        )}
        <div className={styles.topCategories}>
          <div ref={topCategoryScrollRef}></div>
          <CoveringSpinner isLoading={loading} fillWidth>
            <ItemsExpander
              extendGutters
              closedHeight={'180px'}
              handleCallback={handleCallback}
              testid={'categories'}
              numDesktopColumns={longestValue > 20 ? 3 : 4}
              items={
                items && items.length > 0
                  ? items
                      .filter((x) => !x.excludeFromTopCategories)
                      .map((option, i) => {
                        const o = option
                        if (i === 0)
                          return (
                            <>
                              <div className={styles.spacer}></div>
                              <TopCategoryCard
                                option={o}
                                key={`top_category_${o.displayValue}_${i}`}
                                dataTestId={`top_category_${o.displayValue}_${i}`}
                                index={i}
                                priority={i < 6}
                                anyImages={
                                  anyCategoryImages || anySuggestedFacetImages
                                }
                                setIsLoading={setLoading}
                                spaEnabled={spaEnabled}
                                isCriticalAttribute={
                                  categoryFacet?.isCriticalAttribute
                                }
                                isSearchPage={isSearchPage}
                                isSuggestedFacets={suggestedFacets}
                                isNewEngineFacet={isNewEngineFacet}
                                facetName={categoryFacet?.facetName}
                                totalCards={
                                  items.filter(
                                    (x) => !x.excludeFromTopCategories,
                                  ).length
                                }
                              />
                            </>
                          )
                        return (
                          <TopCategoryCard
                            option={o}
                            key={`top_category_${o.displayValue}_${i}`}
                            dataTestId={`top_category_${o.displayValue}_${i}`}
                            index={i}
                            priority={i < 6}
                            anyImages={
                              anyCategoryImages || anySuggestedFacetImages
                            }
                            setIsLoading={setLoading}
                            spaEnabled={spaEnabled}
                            isSearchPage={isSearchPage}
                            isSuggestedFacets={suggestedFacets.length > 0}
                            isNewEngineFacet={isNewEngineFacet}
                            facetName={categoryFacet?.facetName}
                          />
                        )
                      })
                  : emptyArray
              }
            />
          </CoveringSpinner>
        </div>
      </div>
    </>
  )
}

const TopCategoryCard = ({
  option,
  dataTestId,
  anyImages,
  setIsLoading,
  spaEnabled,
  priority,
  isCriticalAttribute,
  isSearchPage,
  isSuggestedFacets,
  isNewEngineFacet,
  facetName,
  totalCards,
}) => {
  const categoryCardRef = useRef(null)
  const Navigate = usePrpStore((x) => x.Navigate)
  const { logRocketTrack } = useLogRocket()
  const { sendSegmentTrackEvent } = useSegment()

  const breadcrumb = useProductResultPage((x) => x.breadcrumb)
  const selectedFacetOptions = useProductResultPage(
    (x) => x.selectedFacetOptions,
  )
  const attribution = useProductResultPage((x) => x.attribution)

  async function handleCardClick(e) {
    if (spaEnabled) {
      if (isSuggestedFacets) {
        logRocketTrack(`SuggestedFacetClicked`)
        sendSegmentTrackEvent(
          createClickedSegmentEvent(`SuggestedFacet_${option?.facetType}`)
        )
        Navigate(e, option.facetOptionLink.url, setIsLoading)
      } else {
        logRocketTrack(
          `TopCategoryClicked_${isCriticalAttribute ? 'CriticalAttribute' : 'Regular'}_${isSearchPage ? 'Search' : 'Shop'}`
        )
        Navigate(e, option.facetOptionLink.url, setIsLoading)
      }
    }
  }

  const router = useRouter()
  const segmentCategory = (breadcrumb || [])
    .map((crumb) =>
      crumb.displayText.toLowerCase() === 'home' ? '' : crumb.displayText,
    )
    .filter((crumb) => crumb)
    .join(' > ')
  const sortType = router.query.sortType || 'relevance'
  const segmentSortType =
    sortType === 'pricelowhigh' || sortType === 'pricehighlow'
      ? 'price'
      : sortType === 'highestrated'
        ? 'rating'
        : 'relevance'
  const segmentSortValue =
    sortType === 'pricehighlow' || sortType === 'highestrated'
      ? 'desc'
      : sortType === 'pricelowhigh'
        ? 'asc'
        : null
  const segmentFilters = []
  selectedFacetOptions.forEach((facet) =>
    facet.searchResponseFacetOptions.forEach((opt) => {
      segmentFilters.push({
        type: facet.displayText,
        value: opt.id,
      })
    }),
  )

  segmentFilters.push({
    type: facetName,
    value: option.id,
  })

  const topCategoryString = `TopCategory_${
    isNewEngineFacet && attribution?.is_new_engine
      ? 'NewEngine'
      : isCriticalAttribute
        ? 'CriticalAttribute'
        : 'Regular'
  }_${isSearchPage ? 'Search' : 'Shop'}`

  const segmentEvent = useMemo(() => ({
    event: 'Product List Filtered',
    properties: {
      list_id: isSearchPage ? 'search_prp_results' : 'shop_prp_results',
      category: segmentCategory,
      filters: segmentFilters,
      attribution: attribution,
      facet_type: topCategoryString,
      sorts: [{ type: segmentSortType, value: segmentSortValue }],
    },
  }), [isSearchPage, segmentCategory, segmentFilters, attribution, topCategoryString, segmentSortType, segmentSortValue])

  let noWrapClassName = ''
  if (!anyImages) {
    if (totalCards == 1) noWrapClassName = styles.totalNoWrap
    else noWrapClassName = styles.noWrap
  }

  return (
    <Card
      href={spaEnabled ? undefined : option.facetOptionLink.url}
      layer={1}
      classNames={classNames(
        styles.topCategoryCard,
        !anyImages ? styles.noImageCard : '',
        'lr_topCatCard',
      )}
      noPadding
      width={'100%'}
      ref={categoryCardRef}
      onClick={handleCardClick}
      segmentEvent={segmentEvent}
      dataTestId={dataTestId}
      nofollow={true}
    >
      <div className={styles.topCategoryCardContent}>
        <Typography
          className={noWrapClassName}
          tone={'contrast'}
          font={'bold'}
          size={0.875}
        >
          {option.displayValue}
        </Typography>

        {anyImages ? (
          <Image
            width={option?.facetType === 'GA_Brand' ? '100' : '64'}
            height={option?.facetType === 'GA_Brand' ? '34' : '64'}
            priority={priority}
            src={
              option.thumbnailImageUrl
                ? option.thumbnailImageUrl
                : 'https://content.speedwaymotors.com/OtherImages/missingimage2.jpg'
            }
            alt={''}
          />
        ) : (
          false
        )}
      </div>
    </Card>
  )
}

export default Categories
