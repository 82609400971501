import React, { useContext } from 'react'

import { createClickedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents'

import { NavigationContext } from 'Clutch/Contexts/Navigation/NavigationContext'

import Button from 'Clutch/Molecules/Button'

import Card from 'Clutch/Atoms/Card'
import Svg from 'Clutch/Atoms/Svg'
import Typography from 'Clutch/Atoms/Typography'

import styles from './selectVehiclePrompt.module.scss'

const SelectVehiclePrompt = () => {
  const { dispatch: navigationDispatch } = useContext(NavigationContext)

  function handleClick() {
    navigationDispatch({
      type: 'setCustomerProjectDrawerState',
      payload: {
        garageState: {
          isCustomerProjectDrawerOpen: true,
        },
      },
    })
  }

  return (
    <Card fill noPadding layer={0} className={styles.card}>
      <div className={styles.cardContentsNoVehicle}>
        <div className={styles.title}>
          <div className={styles.spaceBetweenUnselected}>
            <div className={styles.itemText}>
              <Svg icon={'car'} size={1.25} className={styles.svg} />
              <Typography className={styles.exactFitText}>
                Find Parts That Fit Your Vehicle
              </Typography>
            </div>
            <Button
              brand={'secondary'}
              size={'small'}
              className={styles.changeSelected}
              onClick={handleClick}
              segmentEvent={createClickedSegmentEvent('PrpSelectVehicle')}
              testingName={'PrpSelectVehicle'}
            >
              <Typography font={'bold'}>{'Select A Vehicle/Engine'}</Typography>
            </Button>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default SelectVehiclePrompt
