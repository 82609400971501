import { useState } from 'react'

import LandingPageLinkRack from 'Clutch/Organisms/LandingPageLinkRack'

import CoveringSpinner from 'Clutch/Atoms/CoveringSpinner'
import Typography from 'Clutch/Atoms/Typography'

import FacetOptionImageLink from '../../components/facetOptionImageLink/facetOptionImageLink'
import useProductResultPage from '../../hooks/useProductResultPage'
import Campaign from '../campaign/campaign'
import styles from './facets.module.scss'

const Facets = () => {
  const searchResponseFacets = useProductResultPage(
    (x) => x.searchResponseFacets,
  )
  const [loading, setLoading] = useState(false)
  const facets = searchResponseFacets.filter(
    (facet) => facet.isForFacetResultPage,
  )

  return (
    <div className={styles.facets}>
      {(facets || []).map((facet, i) => {
        const options = [...facet.searchResponseFacetOptions]
        const sortedFacets =
          options.sort((a, b) => a.orderedSortIndex - b.orderedSortIndex) || []

        const formatData = (facets) => {
          const links = []
          for (let i = 0; i < facets.length; i++) {
            links.push({
              displayValue: facets[i].displayValue,
              url: facets[i].facetOptionLink.url,
              imageUrl: facets[i].thumbnailImageUrl,
            })
          }
          return links
        }

        return (
          <div className={styles.facetContainer} key={`facet_${facet.facetName}_${i}`}>
            <CoveringSpinner
              className={styles.facetBlock}
              size={2}
              isLoading={loading}
            >
              <Typography font={'bold'} size={1.25}>
                {facet.facetName == 'Subcategory'
                  ? 'Subcategories'
                  : facet.facetName}
              </Typography>
              <LandingPageLinkRack links={formatData(sortedFacets)} />
              <br />
            </CoveringSpinner>
          </div>
        )
      })}
    </div>
  )
}

export default Facets
