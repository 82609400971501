import { useContext } from 'react'

import ClassNames from 'classnames'

import LayerColorFinder from 'Clutch/Utilities/LayerColorFinder'

import { NavigationContext } from 'Clutch/Contexts/Navigation/NavigationContext'

import Svg from 'Clutch/Atoms/Svg'
import TextLink from 'Clutch/Atoms/TextLink'
import Typography from 'Clutch/Atoms/Typography'

import useProductResultPage from '../../../hooks/useProductResultPage'
import useVehicleSidebar from '../../../hooks/useVehicleSidebar'
import SelectedFacets from '../../options/selectedFacets/selectedFacets'
import styles from './sidebarDrawerFacets.module.scss'
import { usePrpStore } from '/contexts/prpContext'
import styled from 'styled-components'

const SidebarDrawerFacets = () => {
  const selectedFacets = useProductResultPage((x) => x.selectedFacetOptions)

  const { isVehicleSelected, vehicleName } = useVehicleSidebar(selectedFacets)
  const CloseSidebarDrawer = usePrpStore((x) => x.CloseSidebarDrawer)
  const { dispatch: navigationDispatch } = useContext(NavigationContext)

  const brand = 'secondary'
  const backgroundColor = LayerColorFinder(3, false, brand, null)

  const filterFacetsCount = selectedFacets?.filter(
    (currentFacet) => currentFacet.searchResponseFacetOptions,
  ).length
  const facetsSelected = filterFacetsCount > 0

  let buttonText = 'Select Vehicle/Engine'
  if (isVehicleSelected) buttonText = vehicleName

  function handleCloseClick() {
    navigationDispatch({
      type: 'setCustomerProjectDrawerState',
      payload: {
        garageState: {
          isCustomerProjectDrawerOpen: true,
        },
      },
    })
    CloseSidebarDrawer()
  }

  return (
    <div
      className={
        facetsSelected
          ? styles.onlyChips
          : ClassNames(styles.scroll, styles.facetSelected)
      }
    >
      <StyledChip
        backgroundColor={backgroundColor}
        borderColor={backgroundColor}
        onClick={handleCloseClick}
        className={styles.chip}
        as={'div'}
      >
        <span
          className={styles.chip_contents}
          data-testid={`filterByVehicleButton-mobile`}
        >
          <Typography size={0.875} className={styles.chip_value}>
            {buttonText}
          </Typography>
          <Svg
            icon={isVehicleSelected ? 'close' : 'chevron'}
            tone={'contrast'}
            size={0.5}
            className={styles.vehicleSelectIcon}
          ></Svg>
        </span>
      </StyledChip>
      <SelectedFacets isDesktop={false} location={'mobile'} wrap />
    </div>
  )
}

const StyledChip = styled(TextLink)`
  background-color: ${(props) => `${props.backgroundColor}`};
  border-color: ${(props) => `${props.borderColor}`};
`

export default SidebarDrawerFacets
