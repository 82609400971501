import React, {useMemo} from 'react'
import Expander from 'Clutch/Organisms/Expander/Expander'
import LandingPageLinkRack from 'Clutch/Organisms/LandingPageLinkRack'

import Typography from 'Clutch/Atoms/Typography'

import styles from './additionalLinks.module.scss'

const AdditionalLinks = ({ title, links }) => {


  const buttonProps = useMemo(() => ({
    intent: 'action',
    fill: true,
  }), []);

  if (!links) {
    return null
  }

  const segmentEvent = (link) => {
    //leaving out for now
    return null
  }

  for (let i = 0; i < links.length; i++) {
    links[i].segmentEvent = segmentEvent(links[i])
    links[i].displayValue = links[i].text
    links[i].nofollow = links[i].noFollow
  }



  return (
    <>
      <div className={styles.sectionWrapper} id={title.split(' ').join('_')}>
        {title && title !== 'blank' && (
          <Typography
            as={'h2'}
            font={'bold'}
            size={1.25}
            className={styles.title}
          >
            {title}
          </Typography>
        )}
        <Expander
          closedHeight={'180px'}
          count={links.length}
          mobileHorizontalScroll={true}
          testid={'additional_links'}
          buttonProps={buttonProps}
        >
          <LandingPageLinkRack links={links} />
        </Expander>
      </div>
    </>
  )
}

export default AdditionalLinks
